<template>
  <div class="text-left">
    <div class="bloc bg-white rounded-6 p-6 mt-6 text-left" v-if="donnes.clientMeasures">
      <div class="text-c16 font-c6 text-black">
        Mesures du client
      </div>

      <div class="flex justify-end">
        <div class="w-3/4"/>
        <div class="text-c16 font-c6 text-black mt-6 flex w-1/4 justify-end text-right">
          <div class="w-1/2">OD</div>
          <div class="w-1/2 ml-4">OG</div>
        </div>
      </div>

      <div class="mt-6 text-c14">
        <div class="flex items-center">
          <div class="w-3/4 text-60">Écart pupillaire</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.ecart}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.ecart}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Hauteur de montage</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.hMontage}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.hMontage}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Hauteur calibrée</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.hCalibre}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.hCalibre}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Largeur du calibre</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.lCalibre}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.lCalibre}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Angle pantoscopique</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.aPant}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.aPant}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Galbe de la monture</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.gMonture}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.gMonture}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Distance verre - oeil</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.dVerre}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.dVerre}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Distance de lecture</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.dLecture}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.dLecture}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Diamètre</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.diametre}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.diametre}}</div>
          </div>
        </div>

        <div class="flex items-center mt-3">
          <div class="w-3/4 text-60">Pont</div>
          <div class="flex w-1/4">
            <div class="w-1/2 text-right">{{donnes.clientMeasures.od.pont}}</div>
            <div class="w-1/2 text-right ml-4">{{donnes.clientMeasures.og.pont}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Index",

  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
    }
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}

.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
  z-index: 1;
}

</style>
