<template>
  <div
    class="text-left p-4 cursor-pointer items-center"
    :style="{'font-size': size, 'text-align': align, 'display': display, 'height': height, 'border': border, 'color': color, 'border-radius': radius, 'background-color': background}"
    @click="openUpload()"
  >
    <div
      v-if="file === null"
      class="flex justify-center items-center"
    >
      <icon
        v-if="icon !== null"
        :data="icon"
        height="30"
        width="30"
        class="cursor-pointer text-center"
        original
      />
      <div class="ml-2" v-if="display === 'flex'"></div>
    </div>

    <div
      v-if="file === null"
      class="mt-2 w-full"
    >
      {{ label }}
    </div>

    <div
      v-if="file !== null"
      class="flex items-center"
    >
      <div class="w-1/6">
        <icon
          :data="icon"
          height="40"
          width="40"
          class="cursor-pointer text-center"
          original
        />
      </div>

      <div class="ml-6 w-5/6 text-90 font-c5 text-c14">
        <div class="w-full">
          {{ placeholder.substr(0, 20) }}
          <span v-if="placeholder.length > 20">...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",

  components: {
  },
  props: {
    icon: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    display: {
      type: String,
      default: 'block'
    },
    background: {
      type: String,
      default: 'black'
    },
    border: {
      type: String,
      default: 'none'
    },
    align: {
      type: String,
      default: 'center'
    },
    size: {
      type: String,
      default: '16px'
    },
    radius: {
      type: String,
      default: '0px'
    },
    height: {
      type: String,
      default: '90px'
    },
    color: {
      type: String,
      default: '#fff'
    },
  },

  data () {
    return {
      file: null,
      url: null,
      placeholder: null
    }
  },

  created () {
  },

  methods: {
    openUpload () {
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', 'image/*')
      input.onchange = e => {
        this.file = e.target.files[0]
        this.url = URL.createObjectURL(this.file)
        this.placeholder = e.target.files[0].name
        this.$emit('info', this.file)
      }
      input.click()
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}


</style>
